@import url('https://kit.fontawesome.com/b191e00287.css');
app-root{
    width: 100%;
    height: 100%;
}
body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #333;
}

.bg-img {
    width: 50%; /* or any specific width you need */
    background-image: url('assets/bg-img.png');
    background-size: cover; /* or contain, depending on your needs */
    background-position: center; /* centers the image */
    background-repeat: no-repeat; /* prevents the image from repeating */
    padding: 30px;
    margin-right: auto;
}


.drop-area {
    display: flex;
    align-items: center;
    border: 1px dashed #776CEC;
    padding: 28px 20px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #F5FAFF;
    margin: 10px 0;
}
.drop-area i {
    font-size: 2em;
    margin-right: 15px;
}
.drop-area .text-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.drop-area h6 {
    color: #020428;
    font-size: 14px;
    font-weight: 500;
    margin: 2px 0;
}

.drop-area p {
    color: rgba(2, 4, 40, 0.6);
    font-size: 12px;
    font-weight: 400;
    margin: 2px 0;
}

.drop-area button {
    background-color: #FBFDFE;
    color: #776CEC;
    border: 1px solid #776CEC;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    margin-left: 15px;
}
.drop-area button:hover {
    background-color: #776CEC;
    color: #fff;
}
.drop-area input[type="file"] {
    display: none;
}
.drag-over{
 border:  2px solid #776CEC;
}
/* uploading bar */

.uploading {
    display: flex;
    align-items: center;
    border: 1px dashed #EAE2FD;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin: 10px 0;
}

.uploading .icon {
    background-color: #E9E3F8;
    padding: 15px;
    border-radius: 8px;
    margin-right: 10px;
}

.uploading .icon .fa-light.fa-file {
    color: #AC96E4;
}

.uploading .discription-column {
    flex: auto;
}

.uploading .discription-column .text-column {
    display: flex;
    justify-content: space-between;
}
.uploading .discription-column .progress-column {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.uploading .discription-column span{
    color: #575361;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.2px;
}

.uploading .discription-column p{
    color: #857E95;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
    margin: 0;
}

.uploading .discription-column .progress-column .progress-bar {
    width: 90%;
    background-color: #e0e0e0; /* Light grey background */
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    height: 6px; /* Height of the progress bar */
}

.uploading .discription-column .progress-column .progress {
    background-color: #794fed; /* Color of the progress bar */
    height: 100%; /* Fill the container height */
    width: 0%; /* Initial width, will be updated dynamically */
    transition: width 0.4s ease; /* Smooth transition for width change */
}

.uploading .discription-column .progress-column .progress-number span {
    color: #9892A6;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
}


/* success bar */

.success {
    display: flex;
    align-items: center;
    border: 1px dashed #EAE2FD;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin: 10px 0;
}

.success .icon {
    background-color: #DAF2D9;
    padding: 15px;
    border-radius: 8px;
    margin-right: 10px;
}

.success .icon .fa-light.fa-file {
    color: #73B172;
}

.success .discription-column {
    flex: auto;
}

.success .discription-column .text-column {
    display: flex;
    justify-content: space-between;
}
.success .discription-column .progress-column {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.success .discription-column span{
    color: #575361;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.2px;
}

.success .discription-column p{
    color: #857E95;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
    margin: 0;
}

.success .discription-column .progress-column .progress-bar {
    width: 90%;
    background-color: #e0e0e0; /* Light grey background */
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    height: 6px; /* Height of the progress bar */
}

.success .discription-column .progress-column .progress {
    background-color: #73B172; /* Color of the progress bar */
    height: 100%; /* Fill the container height */
    width: 0%; /* Initial width, will be updated dynamically */
    transition: width 0.4s ease; /* Smooth transition for width change */
}

.success .discription-column .progress-column .progress-number span {
    color: #73B172;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
}

/* Error */

.error {
    display: flex;
    align-items: center;
    border: 1px dashed #EAE2FD;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin: 10px 0;
}

.error .icon {
    background-color: #F2D9D9;
    padding: 15px;
    border-radius: 8px;
    margin-right: 10px;
}

.error .icon .fa-light.fa-file {
    color: #E36363;
}

.error .discription-column {
    flex: auto;
}

.error .discription-column .text-column {
    display: flex;
    justify-content: space-between;
}
.error .discription-column .progress-column {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.error .discription-column span{
    color: #575361;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.2px;
}

.error .discription-column p{
    color: #857E95;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
    margin: 0;
}

.error .discription-column .progress-column .progress-bar {
    width: 90%;
    background-color: #e0e0e0; /* Light grey background */
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    height: 6px; /* Height of the progress bar */
}

.error .discription-column .progress-column .progress {
    background-color: #E36363; /* Color of the progress bar */
    height: 100%; /* Fill the container height */
    width: 0%; /* Initial width, will be updated dynamically */
    transition: width 0.4s ease; /* Smooth transition for width change */
}

.error .discription-column .progress-column .progress-number span {
    color: #E36363;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
}

.submit-btn {
    width: 100%;
    height: 40px;
    color: #fff;
    background-color: #776CEC;
    border-radius: 8px;
    border: none;
    font-size: 16px;
}


.section-card {
    width: 100%;
    height: 100%;
}
.card_row {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    height: 100%;
}

.form-area {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.form-area .form-card {
    width: 75%;
}

.heading {
    font-size: 30px;
    font-weight: 500;
    color: #020428;
    line-height: 40.98px;
    margin: 2px 0;
}
.sub-heading {
    font-size: 16px;
    font-weight: 500;
    color: #292731;
    line-height: 27.2px;
}


@media (max-width: 1499px) {
    .img-area {
        width: 40%;
        padding: 20px;
    }
    .form-area {
        width: 60%;
        padding: 10px 30px;
    }
    .logo {
        width: 40%;
    }
}


@media (max-width: 1199px) {
    .img-area {
        padding: 20px;
    }
    .form-area {
        padding: 10px 20px;
    }
    .heading {
        font-size: 26px;
        line-height:32.98px;
        margin: 2px 0;
    }
    .sub-heading {
        font-size: 14px;
        line-height: 22.2px;
        margin: 2px 0;
    }

    .drop-area, .progress-section .uploading,
    .progress-section .success,
    .progress-section .error {
        margin: 8px 0;
        padding: 10px;
    }    
    .drop-area i {
        font-size: 20px;
    }

    .drop-area h6 {
        font-size: 12px;
        margin: 1px 0;
    }

    .drop-area p {
        font-size: 10px;
        margin: 1px 0;
    }

    .drop-area button {
        padding: 8px 15px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 8px;
    }
    .progress-section .icon {
        padding: 10px;
        border-radius: 6px;
    }
    .progress-section .action-icon .fa-light {
        font-size: 12px;
    }
    .progress-section .name span {
        font-size: 12px;
        line-height: 14.2px;
    }
    .progress-section .name p {
        font-size: 10px;
        line-height: 13.6px;
    }
    .submit-btn {
        font-size: 14px;
    }
}

@media (max-width: 999px) {
    .img-area {
        padding: 20px;
    }
    .form-area {
        padding: 10px 20px;
    }
    .heading {
        font-size: 22px;
        line-height:28.98px;
        margin: 2px 0;
    }
    .sub-heading {
        font-size: 10px;
        line-height: 18.2px;
        margin: 2px 0;
    }

    .drop-area, .progress-section .uploading,
    .progress-section .success,
    .progress-section .error {
        margin: 8px 0;
        padding: 10px;
    }    
    .drop-area i {
        font-size: 15px;
        margin-right: 5px;
    }

    .drop-area h6 {
        font-size: 10px;
        margin: 1px 0;
    }

    .drop-area p {
        font-size: 8px;
        margin: 1px 0;
    }

    .drop-area button {
        padding: 6px 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 6px;
        margin-left: 5px;
    }
    .progress-section .icon {
        padding: 8px;
        border-radius: 6px;
    }
    .progress-section .action-icon .fa-light {
        font-size: 10px;
    }
    .progress-section .name span {
        font-size: 10px;
        line-height: 12.2px;
    }
    .progress-section .name p {
        font-size: 8px;
        line-height: 11.6px;
    }
    .submit-btn {
        font-size: 12px;
        height: 30px;
    }
    .progress-section .progress-number span {
        font-size: 8px !important;
        line-height: 10.6px !important;
    }
}


@media (max-width: 768px) {
    .section-card {
        width: 100%;
        align-items: start;
    }
    .card_row {
        display: block;
    }
    .img-area {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        height: 125px;
    }
    .form-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }
    .form-area .form-card {
        width: 75%;
    }
    .form-area .form-card .heading, .form-area .form-card .sub-heading{
        text-align: center;
    }
}


/* OTP SCREEN */

.otp-input-container {
    display: flex;
    justify-content: space-between;
    gap: 24px; /* space between input boxes */
    margin: 20px 0;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .otp-input:focus {
    outline: none;
    border-color: #776CEC;
    box-shadow: 0 0 5px rgba(119, 108, 236, 0.5);
  }

  @media (max-width: 1299px) {
    .otp-input {
        width: 35px;
        height: 35px;
    }
  }

  @media (max-width: 1199px) {
    .otp-input {
        width: 30px;
        height: 30px;
    }
  }

  @media (max-width: 1099px) {
    .otp-input {
        width: 28px;
        height: 28px;
    }
    .otp-input-container {
        gap: 20px; /* space between input boxes */
      }
  }
  @media (max-width: 999px) {
    .otp-input-container {
        gap: 0px; /* space between input boxes */
      }
  }
  
  @media (max-width: 768px) {
    .otp-area .form-card {
        width: 60%;
    }
  }
  
  @media (max-width: 599px) {
    .otp-input {
        width: 22px;
        height: 22px;
    }
    .otp-input {
        font-size: 12px;
      }
  }

  @media (max-width: 449px) {
    .otp-area .form-card {
        width: 75%;
    }
  }